<template>
  <div class="merchant-add">
    <GlobalChunk icon="search" :title="$route.query.type === 'edit'?'编辑商家':'添加商家'" :padding="[32, 0, 95, 55]">
      <el-row>
        <el-col :span="12">
          <GlobalForm
            :init-data="initData"
            :form-rules="formRules"
            :form-item-list="formItemList"
            @handleConfirm="handleConfirm"
            confirmBtnName="提交"
          >
            <template v-slot:operation>
              <p v-if="$route.query.type === 'edit'" class="phone_tip lv_fc_red">说明：联系电话即登录手机号，修改后创建人将退出登录，请谨慎操作</p>
              <el-form-item
                  class="el_from"
                  label="省市区"
                  :required="true"
              >
                <Provinces
                    @areaID="areaChild"
                    @provinceID="provinceChild"
                    @cityID="cityChild"
                    :addressInfo="addressInfo"
                    :optionsProvince="optionsProvince"
                    :optionsArea="optionsArea"
                    :optionsCity="optionsCity"
                />
              </el-form-item>
            </template>

            <el-form-item class="el_from" label="成立年限:" slot="establishTimeNum">
              <el-input v-model="initData.establishTimeNum" placeholder="输入商家成立的时间年限（50以内的正整数）" @input="inputNumber($event, initData, 'establishTimeNum', 0, 50)">
                <template slot="append">
                  年
                </template>
              </el-input>
            </el-form-item>

            <el-button slot="btnSlot" @click="FromBack">返回</el-button>
          </GlobalForm>
        </el-col>
      </el-row>
    </GlobalChunk>
    <el-dialog title="提交成功提醒" :visible.sync="successfullShow" width="500px" :before-close="closesuccs">
        <div style="text-align: center;font-weight: bold;">提交成功！</div>
        <div v-if="!updateCodedata.contactIsUpdate && !updateCodedata.phoneIsUpdate"
          style="text-align: center;padding-bottom: 20px;font-weight: bold;">创建人姓名及手机号未变更。</div>
        <div v-else style="text-align: center;padding-bottom: 20px;font-weight: bold;">创建人姓名账号已修改成功！小程序登录信息已重置，需重新登录。
        </div>
        <div v-if="updateCodedata.contactIsUpdate || updateCodedata.phoneIsUpdate">
          <div style="margin-bottom: 10px;">
            <p>创建人姓名：{{ updateCodedata.contactBefore }}{{ updateCodedata.contactAfter ? '→' : '' }}<span
                style="color: red;">{{ updateCodedata.contactAfter }}</span></p>
          </div>
          <div style="margin-bottom: 10px;">
            <p>创建人登录手机号：{{ updateCodedata.contactPhoneBefore }}{{ updateCodedata.contactPhoneAfter ? '→' : '' }}<span
                style="color: red;">{{ updateCodedata.contactPhoneAfter }}</span></p>
          </div>
          <div style="margin-bottom: 10px;">
            <p v-if="!updateCodedata.merchantPcAccFailMsg">回收商后台登录账号：{{ updateCodedata.merchantPcAccBefore ||"--"}}{{ updateCodedata.merchantPcAccAfter ? '→' : '' }}<span
                style="color: red;">{{ updateCodedata.merchantPcAccAfter }}</span></p>
            <p v-else>回收商后台登录账号：<span style="color: red;">{{updateCodedata. merchantPcAccFailMsg }}</span></p>
          </div>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="closesuccs">关闭</el-button>
        </span>
      </el-dialog>
    <el-dialog
      class="acc_dialog"
      title="确认提示"
      :visible.sync="goolgeShow"
      :close-on-click-modal="false"
      width="500px"
    >
      <!--
      <div
        style="
          font-size: 16px;
          color: #666666;
          height: 100px;
          line-height: 50px;
        "
      >
        <div style="text-align: center;font-weight:700">是否确认{{ openorclose=='开启提示'?'开启':'关闭' }}</div>
        <div class="command">
          <div>动态口令：</div>

          <el-input

            v-model="formOpenPc.command"
            placeholder="请输入谷歌动态口令"
            oninput="this.value = this.value.replace(/[^0-9a-zA-Z]/ig,'')"
          ></el-input>

        </div>
      </div> -->

      <div
       style="font-size: 14px; color: rgb(255, 104, 123); margin: 0 0 0 0"
      >
        {{typeedit ? '确定提交修改，该回收商所有账号均会退出登录，请谨慎操作。' : '新增前请与商户核实清楚基础资料信息。'}}
      </div>
      <div
        style="
          font-size: 16px;
          color: #666666;
          height: 100px;
          line-height: 50px;
        "
      >
        <div style=" margin-bottom: 20rpx; text-align: left">
        {{typeedit ? '是否确认提交修改回收商信息？' : '确认新增该回收商家？'}}


        </div>
        <div class="command">
          <div>动态口令：</div>
          <el-input
            v-model="goolgeText"
            placeholder="请输入谷歌动态口令"
            oninput="this.value = this.value.replace(/[^0-9a-zA-Z]/ig,'')"
          ></el-input>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="BeautifulClosed">取消</el-button>
        <el-button type="primary" @click.native="sumitclick">确定</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>
import {
  formValidateMobile,
  formValidateNumber,
  formValidateNumberEight,
} from '@/utils/form-validate'
import { inputNumber } from '@/utils/common'
import Provinces from '@/components/plugins/provinces'
import _api from '@/utils/request'
export default {
  name: 'merchant-add',
  components: { Provinces },
  data() {
    return {
      successfullShow: false,
      updateCodedata: {
          "contactIsUpdate": false, 
          "phoneIsUpdate": false, 
          "contactBefore": null, 
          "contactAfter": null, 
          "contactPhoneBefore": null, 
          "contactPhoneAfter": null,
           "storePcAccIsUpdate": false, 
           "storePcAccFailMsg": null, 
           "storePcAccBefore": null, 
           "storePcAccAfter": null, 
           "withdrawIsUpdate": false, 
           "withdrawFailMsg": null, 
           "withdrawBefore": null,
            "withdrawAfter": null
        },
      typeedit:false,
      sumitdata: '',
      goolgeText: '',
      goolgeShow: false,
      // ShowUserAccount: false,
      MerchantFormData: [],
      optionsProvince: [],
      optionsCity: [],
      optionsArea: [],
      addressInfo: {
        provinceID: '',
        cityID: '',
        areaID: '',
      },
      initData: {
        merchantId: '',
        // account:"",
        merchantName: '',
        shortName: '',
        contactName: '',
        contactPhone: '',
        province: '',
        merchantAddress: '',
        entryTime: '',
        establishTimeNum: '',
        payDeposit: '',
        paymentType: '01',
        businessLicense: '',
        merchantVividImgs: [],
        merchantLogo: '',
      },
      formItemList: [
        {
          key: 'merchantName',
          type: 'input',
          labelName: '商家名称',
          placeholder: '输入商家名称',
        },
        {
          key: 'shortName',
          type: 'input',
          labelName: '商户简称',
          placeholder: '输入商户简称（6字内）',
        },
        {
          key: 'contactName',
          type: 'input',
          labelName: '联系人',
          placeholder: '输入联系人名称（10字内）',
        },
        {
          key: 'contactPhone',
          isNumber: true,
          type: 'input',
          labelName: '联系电话',
          placeholder: '输入联系电话',
        },
        { slotName: 'operation' },
        // {
        //   key: "province",
        //   slotName: "provinces",
        //   labelName: "省 市 区"
        // },
        {
          key: 'merchantAddress',
          type: 'input',
          labelName: '商家地址',
          placeholder: '输入商家详细地址（限50字内）',
        },
        {
          key: 'entryTime',
          type: 'disabledDate',
          labelName: '入驻时间',
          placeholder: '输入选择入驻时间（不得小于当前时间）',
          valueFormat: 'timestamp',
          pickerOptions: {
            disabledDate(v) {
              return new Date().getTime() >= v.getTime() + 3600 * 1000 * 24
            },
          },
        },
        { slotName: 'establishTimeNum' },
        // {
        //   key: 'establishTimeNum',
        //   type: 'input',
        //   labelName: '成立年限',
        //   placeholder: '输入商家成立的时间年限（50以内的正整数）',
        // },
        // {
        //   key: "payDeposit",
        //   type: "input",
        //   labelName: "保障金缴纳",
        //   placeholder: "输入保障金缴纳仅能输入大于0的正整数且在8位数内"
        // },
        // // { slotName: "payDeposit" },
        // {
        //   key: "paymentType",
        //   type: "radio",
        //   labelName: "打款方式",
        //   option: [
        //     { label: "对公打款", value: '01' },
        //     { label: "对私打款", value: '02' }
        //   ]
        // },
        {
          key: 'businessLicense',
          type: 'upload',
          labelName: '营业执照',
          placeholder:
            '最多支持上传1张图片，大小不超过5M，格式为JPG或PNG，建议尺寸120*120px',
        },
        {
          key: 'merchantLogo',
          type: 'upload',
          labelName: '商家logo',
          placeholder:
            '最多支持上传1张图片，大小不超过5M，格式为JPG或PNG,建议尺寸100*100px',
        },
        {
          key: 'merchantVividImgs',
          type: 'uploadGroup',
          labelName: '商家形象图',
          placeholder:
            '最多支持上传5张图片，大小不超过5M，格式为JPG或PNG，建议尺寸120*120px',
        },
      ],
      formRules: {
        merchantName: [
          {
            required: true,
            trigger: 'blur',
            message: '请输入商家名称'
          },
        ],
        shortName: [
          {
            required: true,
            trigger: 'blur',
            message: '请输入商家名称（6字内）',
            max: 6,
          },
        ],
        contactName: [
          {
            required: true,
            trigger: 'blur',
            message: '输入联系人名称（10字内）',
            max: 10,
          },
        ],
        contactPhone: [
          {
            required: true,
            validator: formValidateMobile,
            trigger: 'blur',
          },
        ],
        merchantAddress: [
          {
            required: true,
            trigger: 'blur',
            message: '输入商家详细地址（限50字内）',
            max: 50,
          },
        ],
        // payDeposit: [
        //   {
        //     required: true,
        //     trigger: "blur",
        //     validator:formValidateNumberEight
        //   }
        // ],
        entryTime: [
          {
            required: true,
            trigger: 'blur',
            message: '请选择入驻时间',
          },
        ],
        businessLicense: [
          {
            required: true,
            trigger: 'blur',
            message: '请上传营业执照',
          },
        ],
        // merchantVividImgs: [
        //   {
        //     required: true,
        //     trigger: "blur",
        //     message: "请上传商家形象图"
        //   }
        // ],
        // paymentType: [
        //   {
        //     required: true,
        //     trigger: "blur",
        //     message: "请选择打款方式"
        //   }
        // ]
      },
    }
  },
  mounted() {
    if (this.$route.query.type === 'edit') {
      this.typeedit = true;
      // this.ShowUserAccount = true;
      _api.ShopTableXQ(this.$route.query.id).then((res) => {
        if (res.code === 1) {
          this.MerchantFormData = res.data
          this.initData.merchantName = this.MerchantFormData.merchantName
          this.initData.shortName = this.MerchantFormData.shortName
          this.initData.contactName = this.MerchantFormData.contactName
          this.initData.contactPhone = this.MerchantFormData.contactPhone
          // this.initData.account = this.MerchantFormData.account;
          this.initData.province = this.MerchantFormData.province
          this.initData.merchantAddress = this.MerchantFormData.merchantAddress
          this.initData.entryTime = new Date(this.MerchantFormData.entryTime)
          this.initData.establishTimeNum =
            this.MerchantFormData.establishTimeNum
          this.initData.payDeposit = this.MerchantFormData.payDeposit
          this.initData.paymentType = this.MerchantFormData.paymentType
          this.initData.businessLicense = this.MerchantFormData.businessLicense
          this.initData.merchantVividImgs =
            this.MerchantFormData.merchantVividImgs
          this.initData.merchantLogo = this.MerchantFormData.merchantLogo
          this.initData.merchantId = this.MerchantFormData.merchantId
          this.addressInfo.provinceID = this.MerchantFormData.provinceId
          this.addressInfo.cityID = this.MerchantFormData.cityId
          this.addressInfo.areaID = this.MerchantFormData.regionId
          console.log(
            this.MerchantFormData.merchantVividImgs,
            'this.MerchantFormData.merchantVividImgs'
          )
          this.provincesMount()
        }
      })
    } else {
      // this.ShowUserAccount = false;
      _api.listAudit(0).then((res) => {
        if (res.code === 1) {
          this.optionsProvince = res.data
        }
      })
    }
  },
  methods: {
    inputNumber,
    closesuccs() {
      this.$router.push({
        path: "/merchant/list"
      })
    },
    provincesMount() {
      _api.listAudit(0).then((res) => {
        if (res.code === 1) {
          this.optionsProvince = res.data
        }
      })
      _api.listAudit(this.MerchantFormData.provinceId).then((res) => {
        this.optionsCity = res.data
      })
      _api.listAudit(this.MerchantFormData.cityId).then((res) => {
        this.optionsArea = res.data
      })
    },
    areaChild(val) {
      this.addressInfo.areaID = val
    },
    provinceChild(val) {
      this.addressInfo.provinceID = val
      this.addressInfo.cityID = ''
      this.addressInfo.areaID = ''
      _api.listAudit(val).then((res) => {
        if (res.code === 1) {
          this.optionsCity = res.data
        }
      })
    },
    cityChild(val) {
      this.addressInfo.cityID = val
      _api.listAudit(val).then((res) => {
        if (res.code === 1) {
          this.optionsArea = res.data
        }
      })
    },

    handleConfirm(data) {
      console.log(data)
      this.sumitdata = data
      this.goolgeText = ''
      const vo = {
        // account:data.account,
        command: this.goolgeText,
        merchantId: this.initData.merchantId,
        provinceId: this.addressInfo.provinceID,
        cityId: this.addressInfo.cityID,
        regionId: this.addressInfo.areaID,
        merchantName: data.merchantName,
        shortName: data.shortName,
        contactName: data.contactName,
        contactPhone: data.contactPhone,
        merchantAddress: data.merchantAddress,
        entryTime: data.entryTime,
        establishTimeNum: data.establishTimeNum,
        // payDeposit: data.payDeposit,
        // paymentType: data.paymentType,
        businessLicense: data.businessLicense,
        merchantVividImgs: data.merchantVividImgs,
        merchantLogo: data.merchantLogo,
        merchantType: '02',
      }

      if (!data.contactPhone) {
        this.$message.error('请输入手机号')
        return
      } else if (!this.addressInfo.areaID) {
        this.$message.error('请选择省市区')
        return
      }

      // if(data.establishTimeNum>50||!data.establishTimeNum){
      //   this.$message.error('请输入正确成立年限(请输入正确成立年限)')
      // }else if(data.payDeposit.toString().length > 8 || !data.payDeposit){
      //   this.$message.error('请输入正确保障金缴纳')
      // }else
      this.goolgeShow = true
    },

    //提交按钮
    sumitclick() {
      let data = this.sumitdata
      if (this.goolgeText == '') {
        this.$message.warning('请输入动态口令')
        return
      }

      const vo = {
        // account:data.account,
        command: this.goolgeText,
        merchantId: this.initData.merchantId,
        provinceId: this.addressInfo.provinceID,
        cityId: this.addressInfo.cityID,
        regionId: this.addressInfo.areaID,
        merchantName: data.merchantName,
        shortName: data.shortName,
        contactName: data.contactName,
        contactPhone: data.contactPhone,
        merchantAddress: data.merchantAddress,
        entryTime: data.entryTime,
        establishTimeNum: data.establishTimeNum,
        // payDeposit: data.payDeposit,
        // paymentType: data.paymentType,
        businessLicense: data.businessLicense,
        merchantVividImgs: data.merchantVividImgs,
        merchantLogo: data.merchantLogo,
        merchantType: '02',
      }

      if (!data.contactPhone) {
        this.$message.error('请输入手机号')
      } else if (!this.addressInfo.areaID) {
        this.$message.error('请选择省市区')
      } else {
        if (this.$route.query.type === 'edit') {
          _api.ShopTableEdit(vo).then((res) => {
            if (res.code === 1) {
              this.updateCodedata = res.data
              this.successfullShow = true
              this.goolgeShow = false
              // this.$router.push({ path: '/merchant/list' })
              this.goolgeShow = false
              this.$message({
                message: '修改成功',
                type: 'success',
              })
            } else {
              this.goolgeShow = true
              this.$message({
                message: res.msg,
                type: 'error',
              })
            }
          })
        } else {
          _api.ShopTableAdd(vo).then((res) => {
            if (res.code === 1) {
              this.$router.push({ path: '/merchant/list' })
              this.goolgeShow = false
              this.$message({
                message: '添加成功',
                type: 'success',
              })
            } else {
              this.$message({
                message: res.msg,
                type: 'error',
              })
            }
          })
        }
      }
    },
    BeautifulClosed() {
      this.goolgeShow = false
    },

    FromBack() {
      this.$router.go(-1)
    },
  },
}
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.merchant-add {
  .el_from {
    margin-left: -120px;
  }
}
.el-dialog__body .acc_dialog_text1 {
  margin-bottom: 40rpx;
  color: red;
}
.command {
  display: flex;
  align-items: center;
}

.command {
  display: flex;
  align-items: center;
}
.command .el-input {
  width: 80%;
}

.phone_tip{
  font-size: 12px;
  transform: translate(15px,-10px);
}
</style>
